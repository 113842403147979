import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Reg } from '../_models/reg';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public notify = new BehaviorSubject<any>('');
  private url = "/api";
  regs: Reg[] = [];
  notifyObservable$ = this.notify.asObservable();

  constructor(private http: HttpClient) { }
  addToCart(reg: Reg) {
    return this.http.post<any>(this.url + '/cart', reg).pipe(map(data => {
      data.test.date = new Date(data.test.date.replace(/-/g, '/').replace(/T/g, ' '));
      return data;
    }));
  }
  getRegsFromCart() {
    return this.http.get<any>(this.url + '/cart').pipe(map(data => {
      data.regs.map(reg => { reg.date = new Date(reg.date); reg.test.date = new Date(reg.test.date.replace(/-/g, '/').replace(/T/g, ' ')) })
      return data;
    }));
  }
  deleteFromCart(id: number) {
    return this.http.delete(this.url + '/cart/' + id);
  }
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
  clearCart() {
    this.regs = [];
    return this.regs;
  }
  getPrice() {
    return this.http.post<any>(this.url + '/cart/price', {});
  }
  orderState(orderId: number) {
    return this.http.post(this.url + '/cart/orderState', orderId);
  }
  orderStatePlayer(playerId: number) {
    return this.http.post(this.url + '/cart/orderStatePlayer', playerId);
  }
  payFree(reg) {
    return this.http.post(this.url + '/regs/pay', reg);
  }
  changeStatus(orderId: number) {
    console.log("orderId = " + orderId);
    return this.http.post(this.url + '/cart/changeStatus', orderId);
  }
}
